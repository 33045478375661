
import Axios from "axios";
import { baseurl } from "../Constants/baseurl";
import { Store } from "./store";
// const FileUpload = require('react-fileupload');
// want don't use so run 'npm uninstall --save react-fileupload'

export const api = {

    invoke: function (method, url, data = {}) {
        let param = null;
        if (method.toLowerCase()=="get") {param=data;data=null;}
        return Axios({
            url: url,
            baseURL: baseurl,
            method: method,
            headers: {
                'x-access-token':Store.getUserToken("")
            },
            params:param,
            data: data
        }
        ).catch(error => {
            return error.response;
        })
    },
    uploadFile:()=>{
        const options={
            baseUrl:baseurl,
            param:{
                'x-access-token':Store.getUserToken("")
            }
        }
    }
}