import React, { Component } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row, FormGroup, Label } from 'reactstrap';
import { Store } from '../../../services/store';
import { service } from '../../../services/service';
import placeholder from '../../../assets/img/placeholder.png'
import logo from '../../../assets/img/brand/logotitle.png'
import CardFooter from 'reactstrap/lib/CardFooter';
class ResetUserPassword extends Component {

    state = {
        newPassword: "",
        rePassword: "",
        token: ""
    };
    componentDidMount() {
        const queryParam = new URLSearchParams(this.props.location.search);
        const token = queryParam.get('access_token');
        this.setState({ token: token });
    }

    handleInputs(evt, prop) {
        let obj = {};
        obj[prop] = evt.target.value;
        this.setState(() => {
            return obj;
        })
    }
    onSubmit() {
        const { rePassword, newPassword, token } = this.state;
        if (!token) { alert("Token expired!"); return }
        if (rePassword && newPassword && (rePassword === newPassword)) {
            service.resetUserPassword(newPassword, token).then(res => {
                console.log("reset", res);
                if (res) {
                    const { data, status } = res;
                    if (status === 204 || status === 200) {
                        alert("Your Password have been reset successfully!")
                        this.props.history.push('/');
                    } else {
                        if (data && data.error && data.error.message) {
                            alert(data.error.message);
                        } else {
                            alert("Echec de l'opération !")
                        }
                    }
                } else {
                    alert("Echec de l'opération !");
                }
            }).catch(err => {
                console.log("reset error", err);
                alert("Echec de l'opération !");
            })
        } else {
            alert("Le mot de passe répété doit également être le même")
        }
    }
    render() {
        return (
            <Row className="justify-content-center login-page">
                <Col md="7" className="bg-secondary">
                    <img src={placeholder} width="500" height="450" ></img>
                </Col>
                <Col className="p-0">
                    <Card className="card-portion">
                        <CardBody>
                            <Form className="flex-view" onSubmit={(e) => { e.preventDefault(); this.onSubmit() }}>
                                <img src={logo} width="150" className="mb-3" />
                                <p className="text-muted">Your very near to <strong className="text-primary">RESET YOUR PASSWORD</strong> Fill the form below and submit</p>
                                <InputGroup className="mb-3">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="icon-lock"></i>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input type="password" placeholder={"Please enter your new password here"} autoComplete="none" value={this.state.newPassword} name="user-newpassword" onChange={(evt) => this.handleInputs(evt, 'newPassword')} />
                                </InputGroup>
                                <InputGroup className="mb-4">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="icon-lock"></i>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input type="password" placeholder="Please re-enter your new password" autoComplete="none" value={this.state.rePassword} name="user-repassword" onChange={(evt) => this.handleInputs(evt, 'rePassword')} />
                                </InputGroup>
                                <Row>
                                    <Col md="12">
                                        <Button color="primary" block className="px-4 btn-pill mt-3" onClick={() => this.onSubmit()}>Submit</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </CardBody>
                        <CardFooter className="text-muted text-center" >
                            <span> {'\u00a9'}
                                <strong className="text-primary"> Kelplat </strong>
                               Tous droits réservés
                            </span>
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
        );
    }
}

export default ResetUserPassword;
