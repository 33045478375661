import React, { Component } from 'react';
import { Link, Router, useHistory } from 'react-router-dom';
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row, FormGroup, Label } from 'reactstrap';
import { Store } from '../../../services/store';
import { service } from '../../../services/service';
import placeholder from '../../../assets/img/placeholder.png'
import logo from '../../../assets/img/brand/logotitle.png'
import CardFooter from 'reactstrap/lib/CardFooter';
class ForgotPassword extends Component {

    state = {
        submitted: false,
        email: "",
        password: ""
    };
    componentDidMount() {
        const isUser = Store.getUser(false) ? true : false;
        if (isUser) {
            this.props.history.push('/');
        }
    }

    handleInputs(evt, prop) {
        let obj = {};
        obj[prop] = evt.target.value;
        this.setState(() => {
            return obj;
        })
    }
    onSubmit() {
        const { email, password } = this.state;
        console.log(email, password);
        service.forgotPassword(email).then(res => {
            console.log("forgot", res);
            if (res) {
                this.setState({ submitted: true })
                const { data, status } = res;
                if (status === 200 || status === 204) {
                    alert("Vous avez un lien de réinitialisation du mot de passe sur votre email. Veuillez vérifier votre boîte aux lettres électronique ainsi que votre dossier spam.");
                    this.props.history.push('/');
                } else {
                    if (data && data.error && data.error.message) {
                        alert(data.error.message);
                    } else {
                        alert("Echec de l'opération !")
                    }
                }
            } else {
                alert("Echec de l'opération !");
            }
        }).catch(err => {
            console.log("login error", err);
            alert("Echec de l'opération !");
        })
    }
    render() {
        return (
            <Row className="justify-content-center login-page">
                <Col md="7" className="bg-secondary">
                    <img src={placeholder} width="500" height="450" ></img>
                </Col>
                <Col className="p-0">
                    <Card className="card-portion">
                        <CardBody>
                            <Form onSubmit={(e) => { e.preventDefault(); this.onSubmit() }} className="flex-view">
                                <img src={logo} width="150" className="mb-3" />
                                <p className="text-muted"><strong className="text-primary">Mot de passe oublié ?</strong> Entrez votre adresse e-mail et soumettez pour réinitialiser votre mot de passe.</p>
                                <InputGroup className="mb-3">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="icon-user"></i>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input type="email" placeholder={"Entrez votre email"} autoComplete="email" value={this.state.email} name="user-email" onChange={(evt) => this.handleInputs(evt, 'email')} />
                                </InputGroup>
                                <Row>
                                    <Col md="12">
                                        <Button color="primary" disabled={this.state.submitted} block className="px-4 btn-pill mt-3" onClick={() => this.onSubmit()}>Valider</Button>
                                    </Col>
                                </Row>
                                <Row className="mt-3 text-center">
                                    <Col md="12">
                                        <Link to="/register">
                                            <Button color="link" className="px-0 text-muted">Vous n'avez pas encore de compte ? <a href="javascript:void(0)"><strong className="text-primary">S'inscrire</strong></a></Button>
                                        </Link>
                                    </Col>
                                </Row>
                            </Form>
                        </CardBody>
                        <CardFooter className="text-muted text-center" >
                            <span> {'\u00a9'}
                                <strong className="text-primary"> Kelplat </strong>
                                Tous droits réservés
                            </span>
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
        );
    }
}

export default ForgotPassword;
