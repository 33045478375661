import React, { Component } from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import { service } from './services/service';
import { Store } from './services/store';
import ForgotPassword from './views/Pages/ForgotPassword/ForgotPassword';
import ResetPassword from './views/Pages/ResetPassword/ResetPassword';
import ResetUserPassword from './views/Pages/ResetUserPassword/ResetUserPassword';

// translation CHARGMENT EN COURS ... = LOADIND
const loading = () => <div className="animated fadeIn pt-3 text-center">Chargement en cours...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const TermsNConditions = React.lazy(() => import('./views/Pages/TermsNConditions/TermsNConditions'));
const RestaurantMenuPage = React.lazy(() => import('./views/Pages/RestaurantMenuPage/RestaurantMenuPage'));
const SetupRest = React.lazy(() => import('./views/Pages/SetupRestaurant/SetupRestaurant'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));
class App extends Component {
  state = {
    isUser: false
  }
  componentDidMount() {
    this.getUser()
  }
  getUser() {
    const userId = Store.getUserId();
    if (userId) {
      service.getUserById(userId).then(res => {
        if (service.isResponse(res)) {
          this.setState({ isUser: true });
        }
      })
    }
  }

  render() {
    const { isUser } = this.state;
    return (
      <HashRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
            <Route exact path="/terms-conditions" name="Terms & Conditions" render={props => <TermsNConditions {...props} />} />
            <Route exact path="/forgot-password" name="Forgot Password" render={props => <ForgotPassword {...props} />} />
            <Route exact path="/reset-password" name="Reset Password" render={props => <ResetPassword {...props} />} />
            <Route exact path="/reset-user-password" name="Reset User Password" render={props => <ResetUserPassword {...props} />} />
            <Route exact path="/restaurant-menu/:id" name="Restaurant Menu" render={props => <RestaurantMenuPage {...props} />} />
            {/* <Route exact path="/setup-restaurant" name="Setup Restaurant" render={props => <SetupRest {...props} />} /> */}
            <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
            <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
            {isUser === true ? <Route path="/" name="Home" render={props => <DefaultLayout {...props} />} /> : <Route exact path="/" name="Login Page" render={props => <Login {...props} />} />}
            <Redirect from='*' to='/login' />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
