import { api } from './apiService';

export const service = {

    isResponse: (res) => {
        if (res) {
            const { data, status } = res;
            if (status === 200) {
                return data;
            } else {
                return null;
            }
        } else {
            return null;
        }
    },
    login: (email, password) => {
        return api.invoke("POST", `users/login?include=user`, { email, password })
    },
    register: (data) => {
        return api.invoke("POST", `users`, data)
    },
    logout: () => {
        return api.invoke("POST", `users/logout`)
    },
    resetPassword: (newPassword, token) => {
        return api.invoke("POST", `appusers/reset-password?access_token=${token}`, { newPassword })
    },
    forgotPassword: (email) => {
        return api.invoke("POST", `users/reset`, { email })
    },
    resetUserPassword: (newPassword, token) => {
        return api.invoke("POST", `users/reset-password?access_token=${token}`, { newPassword })
    },
    getUsers: () => {
        return api.invoke("GET", `users`, { filter: { include: ["restaurants"] } });
    },
    updateUserById: (id, data) => {
        return api.invoke("PATCH", `users/${id}`, data);
    },
    getUserById: (id) => {
        return api.invoke("GET", `users/${id}`);
    },
    getStripeCustomerId:(id)=>{
        return api.invoke("GET", `users/${id}/stripe-customerId`);
    },
    getMembershipPrice:(id)=>{
        return api.invoke("GET", `users/${id}/membership`);
    },
    getMySubscription:(id)=>{
        return api.invoke("GET", `users/${id}/my-subscription`);
    },
    createSubscription:(id,data)=>{
        return api.invoke("POST", `users/${id}/create-subscription`,data);
    },
    getUserRestaurantById: (userId) => {
        return api.invoke("GET", `users/${userId}/restaurants`)
    },
    getRestTags: (userId) => {
        return api.invoke("GET", `users/${userId}/restaurants/tags`)
    },
    linkTag: (userId, tagId) => {
        return api.invoke("PUT", `users/${userId}/restaurants/tags/rel/${tagId}`)
    },
    unlinkTag: (userId, tagId) => {
        return api.invoke("DELETE", `users/${userId}/restaurants/tags/rel/${tagId}`)
    },
    updateRestaurantByUserId: (userId, restaurant) => {
        return api.invoke("PUT", `users/${userId}/restaurants`, restaurant)
    },
    updateRestaurantByRestId: (restaurantId, restaurant) => {
        return api.invoke("PATCH", `restaurants/${restaurantId}`, restaurant)
    },
    getRestaurantByRestId: (restaurantId) => {
        return api.invoke("GET", `restaurants/${restaurantId}`)
    },
    getMenulist: (userId) => {
        return api.invoke("GET", `users/${userId}/restaurants/menulist`)
    },
    updateItemById(userId, itemId, data) {
        return api.invoke("PUT", `users/${userId}/restaurants/items/${itemId}`, data)
    },
    deleteItemById(userId, itemId) {
        return api.invoke("DELETE", `users/${userId}/restaurants/items/${itemId}`)
    },
    submitItemByuserId(userId, data) {
        return api.invoke("POST", `users/${userId}/restaurants/items`, data)
    },
    updateItemByItemId(itemId, data) {
        return api.invoke("PATCH", `items/${itemId}`, data)
    },
    submitTags: (name, type) => {
        return api.invoke("POST", `tags`, { name, type })
    },
    updateTagById: (data, id) => {
        return api.invoke("PATCH", `tags/${id}`, data)
    },
    deleteTagById(id) {
        return api.invoke("DELETE", `tags/${id}`)
    },
    getTags: () => {
        return api.invoke("GET", `tags`)
    },
    getFilteredTags: () => {
        return api.invoke("GET", `tags`, { filter: { where: { active: true } } })
    },
    submitRestaurant: (id, data) => {
        return api.invoke("POST", `users/${id}/restaurants`, data)
    },
    getRestaurants: () => {
        return api.invoke("GET", `restaurants`)
    },
    generateQrCode: (id) => {
        return api.invoke("GET", `users/${id}/restaurants/qrcode`)
    },
    submitItem: (id, data) => {
        return api.invoke("POST", `users/${id}/restaurants/items`, data)
    },
    addTagsToItem: (id, fk, item_tags) => {
        return api.invoke("POST", `users/${id}/restaurants/items/${fk}/link-tags`, item_tags)
    },
    linkOneTagToItem: (id, fk, tagId) => {
        return api.invoke("HEAD", `users/${id}/restaurants/items/${fk}/tags/${tagId}`)
    },
    unlinkOneTagToItem: (id, fk, tagId) => {
        return api.invoke("DELETE", `users/${id}/restaurants/items/${fk}/tags/${tagId}`)
    },
    addTagsToRestaurant: (id, item_tags) => {
        return api.invoke("POST", `users/${id}/restaurants/link-tags`, item_tags)
    },
    removeTagsToItem: (id, fk, item_tags) => {
        return api.invoke("POST", `users/${id}/restaurants/items/${fk}/unlink-tags`, item_tags)
    },
    getItems: (id, filter) => {
        return api.invoke("GET", `users/${id}/restaurants/items`, { filter: filter })
    },
    getItemsByRestaurantId: (restId, filter) => {
        return api.invoke("GET", `restaurants/${restId}/items`, { filter: filter })
    },
    uploadImage: (file, filename) => {
        const data = new FormData();
        data.append('image', file, filename);
        return api.invoke("POST", `containers/uploads/upload`, data)
    }
}