// let url = "http://localhost:3000/api/";
// test url 
let url = "https://clicavis.kitwym.xyz/api/";
// let url = "http://188.165.231.35:9006/api/";
// let url = "http://localhost:9005/api/";

if (process.env.NODE_ENV === 'production') {
    url = 'api/';
}
export const baseurl = url;
export const imageUrl = url +"containers/uploads/download/";
export const qrimageUrl = url +"containers/qrcodes/download/";