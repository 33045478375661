import SecureLS from 'secure-ls';
const sls = new SecureLS();
const prefix = "1594461923517";

export const Store = {
    // utilities
    reset:()=>{
      sls.clear();
    },
    set:(name,item)=> {
      sls.set(prefix+name,item);
    },
    get: (name,arg) => {
      return sls.get(prefix+name) || arg;
    },
    setUserToken: (accessToken) => {
      sls.set(prefix+"accessToken",accessToken);
    },
    setUserId: (userId) => {
      sls.set(prefix+"userId",userId);
    },
    setUser: (user) =>{
      sls.set(prefix+"user",user);
    },
    getUserToken: (arg) => {
      return sls.get(prefix+'accessToken') || arg;
    },
    getUserId: (arg) => {
      return sls.get(prefix+'userId') || arg;
    },
    getUser: (arg) => {
      return sls.get(prefix+'user') || arg;
    }
}
